export const items = [
/* {
    title: 'Accueil',
    icon: 'mdi-home',
    to: '/',
  }, */
  {
    title: 'Administration et finance',
    icon: 'mdi-security',
    children: [
        {
          title: 'Profils ONG',
          icon: 'mdi-account',
          to: '/profile-ong',
        },
        {
          title: 'Administration projet',
          icon: 'mdi-folder',
          to: '/admin-projet',
        },
        {
          title: 'Fiance projet',
          icon: 'mdi-cash-multiple',
          to: '/finance-projet',
        },
    ],
  },
  {
    title: 'Domaine Intervention',
    icon: 'mdi-domain',
    to: '/domaine-intervention',
  },
  {
    title: 'AGR',
    icon: 'mdi-book-open-blank-variant',
    to: '/agr',
  },
  {
    title: 'Tableau Synthese',
    icon: 'mdi-table',
    children: [
      {
        title: 'Visualisation graphe',
        icon: 'mdi-chart-line',
        to: '/visualisation-graphe',
      },
      {
        title: 'Visualisaton Tableau',
        icon: 'mdi-table',
        to: '/visualisation-tableau',
      },
    ],
  },
  /* {
    title: 'Archive',
    icon: 'mdi-package-down',
    to: '',
  },
  {
    title: 'Geoportail',
    icon: 'mdi-earth',
    to: '',
  }, */
  {
    title: 'Parametres',
    icon: 'mdi-cog',
    group: 'parametres',
    children: [
      {
        title: 'Parametrage Domaine intervention',
        icon: 'mdi-book-cog-outline',
        to: '/parametre-domaine-intervention',
      },
      {
        title: 'Parametrage AGR',

        icon: 'mdi-cog-outline',
        to: '/parametre-agr',
      },
      {
        title: 'Compte Admin',
        icon: 'mdi-account-tie-hat',
        to: '/compte-admins',
      },
      {
        title: 'Compte partenaire',
        icon: 'mdi-account-tie',
        to: '/compte-partenaires',
      },
      {
        title: 'Information base',
        icon: 'mdi-information',
        to: '',
      },
    ],
  },
]
